<template>
  <CRow>
    <CCol col="12" sm="12">
      <CCard>
        <CCardHeader>
          {{ $route.name }}
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol col="12" sm="5">
              <CSelect
                :placeholder="$t('search_a') + $t('account')"
                :options="adminsOptions"
                :value.sync="searchData.admin_id"
              />
            </CCol>
            <CCol col="12" sm="5">
              <date-picker
                v-model="searchData.date_range"
                :shortcuts="false"
                :editable="false"
                :clearable="false"
                :not-after="$moment()"
                range-separator="~"
                range
                width="100%"
                value-type="date"
                format="YYYY-MM-DD"
                :input-class="['form-control', 'bg-white', valid_searchData_date_range ? 'is-valid' : 'is-invalid']"
              />
              <small class="form-text text-muted w-100">
                {{ $t('search_is_limited_to_a_date_range_of_up_to_one_month') }}
              </small>
            </CCol>
            <CCol col="12" sm="2">
              <CButton color="primary" block :disabled="valid_searchData_date_range ? false : true" @click="getQualifiedPhones()">{{ $t('search') }}</CButton>
            </CCol>
          </CRow>
          <hr>
          <CRow>
            <CCol col="12" sm="10" class="d-flex align-items-center">
              <font class="text-muted">{{ $t('search_info', searchInfo) }}</font>
            </CCol>
            <CCol col="12" sm="2">
              <vue-excel-xlsx
                class="btn btn-primary btn-block"
                :disabled="data.length === 0"
                :data="excelData()"
                :columns="excelColumns"
                :fileName="excelFilename()"
                :sheetName="$t('phone_search_results')"
                >
                {{ $t('export_search_results') }}
              </vue-excel-xlsx>
            </CCol>
          </CRow>
          <hr>
          <v-client-table :columns="columns" :data="data" :options="options">
            <font slot="remarks" slot-scope="props" v-html="props.row.remarks" />
            <font slot="last_dialing_logs" slot-scope="props" v-html="props.row.last_dialing_logs" />
          </v-client-table>
        </CCardBody>
      </CCard>

    </CCol>
  </CRow>
</template>

<script>
export default {
  name: 'qualified',
  components: {
  },
  data () {
    return {
      columns: [ 'number', 'remarks', 'last_dialing_logs' ],
      data: [],
      options: {
        headings: {
          number: this.$t('phone_number'),
          remarks: this.$t('remarks'),
          last_dialing_logs: this.$t('last_dialing_logs')
        },
        sortable: [ 'number', 'remarks', 'last_dialing_logs' ],
        filterable: [ 'number', 'remarks', 'last_dialing_logs' ]
      },
      excelColumns: [
        { label: this.$t('phone_number'), field: 'number' },
        { label: this.$t('remarks'), field: 'remarks', dataFormat: v => v.replace(/<br\s*\/?>/gi, '\n') },
        { label: this.$t('last_dialing_logs'), field: 'last_dialing_logs', dataFormat: v => v.replace(/<br\s*\/?>/gi, '\n') },
      ],
      adminsOptions: [
        { value: null, label: this.$t('please_select_a') + this.$t('account') + '...' }
      ],
      searchInfo: { at: '-', count: 0, sec: 0 },
      searchData: { admin_id: null, phone_book_id: null, tag_id: null, date_range: [this.$moment({hour: 0}).subtract(7, 'd'), this.$moment({hour: 0})] },
    }
  },
  computed: {
    valid_searchData_date_range () {
      return this.searchData.date_range[0] && this.searchData.date_range[1] && Math.abs(this.$moment(this.searchData.date_range[1]).endOf('d').diff(this.searchData.date_range[0], 'months', true)) <= 1 ? true : false
    },
  },
  mounted: function () {
    this.getAdmins()
  },
  methods: {
    getAdmins () {
      const loader = this.$loading.show()
      this.$store.dispatch('getAdmins').then(res => {
        this.adminsOptions = [{ value: null, label: this.$t('please_select_a') + this.$t('account') + '...' }]
        for (const i in res) {
          this.adminsOptions.push({ value: res[i].id, label: res[i].name + ' (' + res[i].email + ')', name: res[i].name })
        }
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    },
    getQualifiedPhones () {
      if (!this.valid_searchData_date_range) {
        this.$swal('Warning', this.$t('search_is_limited_to_a_date_range_of_up_to_one_month'), 'warning')
        return
      }
      const startMoment = this.$moment()
      const loader = this.$loading.show()
      const searchData = this.$_.clone(this.searchData)
      searchData.date_range[1] = this.$moment(searchData.date_range[1]).endOf('d')
      this.$store.dispatch('getQualifiedPhones', this.$_.clone(searchData)).then(res => {
        const groupData = this.$_.groupBy(res, v => v.number)
        this.data = []
        for (let [key, val] of Object.entries(groupData)) {
          this.data.push({
            number: key,
            remarks: this.$_.filter(this.$_.map(val, v => v.remark ? `${v.admin_name}: ${v.remark}` : null)).join('<br>'),
            last_dialing_logs: this.$_.map(val, row => this.$_.map(row.dialing_logs, v => v.connected ? `${v.admin_name}: [${this.$moment(v.created_at).format('YYYY-MM-DD HH:mm:ss')}] ${this.$t('connected')} (${v.duration} ${this.$t('seconds')})` : `${v.admin_name}: [${this.$moment(v.created_at).format('YYYY-MM-DD HH:mm:ss')}] ${this.$t('not_connected')}`).join('<br>')).join('<br>')
          })
        }
        console.log(this.data)
        this.searchInfo.at = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        this.searchInfo.count = res.length
        this.searchInfo.sec = this.$moment().diff(startMoment) / 1000
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    },
    excelData () {
      return this.data
    },
    excelFilename () {
      const admin_name = this.searchData.admin_id ? this.$_.find(this.adminsOptions, option => option.value === this.searchData.admin_id).name : 'AllAccounts'
      return `${this.$route.name}-[${admin_name}]_${this.$moment(this.searchData.date_range[0]).format('YYYYMMDD')}${this.$moment(this.searchData.date_range[1]).format('YYYYMMDD')}_export_${this.$moment().unix()}`
    },
  }
}
</script>
